/* .bodyWrapper{
    display: flex;
 
    width: 75%;
    flex-direction: column;
height: 100%;
margin: 50px auto;
color: white;
font-weight: bold;
font-family: 'Roboto', sans-serif;
text-align: center;

    
}


.overlay{
    position: absolute;
    height: 100%;
    background-color: rgb(0 0 0 / 35%);
}

.wrapper{
    height: 100vh;
    background-image: url('../../public/Images/trails-7228378_1280.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.leftSide{

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.rightSide{

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.profileImage{
    width: 300px;
    max-width: 100%;
 
}

@media (min-width:768px){
    .rightSide{
        width: 50%;
    }

    .leftSide{
        width: 50%;
    }

    .bodyWrapper{
        flex-direction: row;
        width: 75%;
    }
} */

.profileImage{
    margin-top: 30px;
    width: 300px;
    max-width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 6px 3px white;
}

.bodyWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
    color: white;
    height: calc(100vh - 200px);
    position: relative;
  margin-top: 60px;
}

.text{
    margin-top: 30px;
}

@media (min-width:768px) {
    .bodyWrapper{
        width: 75%;
    margin: 115px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: calc(100vh - 200px);
    }

    .rightSide{
        width: 50%;
        margin: 15px;
    }

    .leftSide{
        width: 50%;
        margin: 15px;
    }
}
