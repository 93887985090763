

.project {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px;
  color: white;

}

.link {
  color: white;
  text-decoration: none;
}

.scrollableContainer{
  height: 600px;
  overflow-y: auto;
  margin: 0 auto; 
  display: flex;
  flex-direction: column;
}

@media (min-width:768px) {
  .scrollableContainer{
    flex-direction: row;
    height: calc(100vh - 300px);
    justify-content: center;
    flex-wrap: wrap;

  }
  .scrollableContainer::-webkit-scrollbar {
    display: none;
  }

  .project{
    width: 300px;
  }

  .wrapper{
    width: 75%;
    margin: 0 auto;
    position: relative;
  
  }
}

