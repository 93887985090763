
  .profileImage{
    height: 400px;
    margin-top: 30px;
  }

  .wrapper{
    position: relative;
    width: 100%;

  }
  
  .link {
    color: white;
    text-decoration: none;
  }
  
  .aboutContent{
    height: 600px;
    overflow-y: auto;
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .text{
    color: white;
    padding: 20px;
    text-align: center;
    height: 100%;
  }

  .header{
    color: white;
    text-align: center;
    margin-top: 40px;
  }

  .containerImage{
    margin: 0 auto;
  }
  
  @media (min-width:768px) {
    .aboutContent{
     width: 70%;
position: relative;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
    }
    .aboutContent::-webkit-scrollbar {
      display: none;
    }

    .containerImage{
        margin-top: 10vh;
    }
  

  
    .wrapper{
      width: 75%;
      margin: 0 auto;
      position: relative;
    
    }
  }
  